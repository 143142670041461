import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {Redirect} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {UploadSettingsFile} from './ApiOperations'
import {IsRestUser} from '../general/GeneralApiOperations'
const mySwal = withReactContent(Swal)
const Settings = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  var isRestUser = false
  const [isLoading, setisLoading] = useState(false)
  const [file, setfile] = useState()
  const [isupdate, setIsUpdate] = useState(false)

  const saveFile = (e: any) => {
    setfile(e.target.files[0])
  }

  const uploadFile = (filetype: string) => {
    setisLoading(true)
    const formData = new FormData()
    formData.append('fileType', filetype)
    formData.append('formFile', file as any)
    UploadSettingsFile(formData)
      .then(() => {
        mySwal.fire('Başarılı !', 'Görsel başarıyla yüklendi.', 'success').then(() => {
          setisLoading(false)
          setIsUpdate(true)
        })
      })
      .catch(() => {
        mySwal
          .fire('Hata !', 'Bir hata oluştu  , lütfen daha sonra tekrar deneyiniz.', 'error')
          .then(() => {
            setisLoading(false)
            setIsUpdate(true)
          })
      })
  }
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      IsRestUser(user.email).then((response) => {
        var value = response.data
        isRestUser = value
      })
    }
    return () => {
      isMounted = false
    }
  }, [])
  if (user.email !== 'info@restyazilim.com') {
    return <Redirect to='/error/404' />
  }
  return (
    <>
      <PageTitle>Genel Ayarlar</PageTitle>
      <div className='card h-100'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='card card-custom  card-stretch shadow pt-5 mb-5 w-100  h-100 text-center'>
                <h4>Giriş Ekranı Arkaplan Görseli</h4>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <img
                        className='w-100'
                        src={toAbsoluteUrl('/media/background/bg-image.png')}
                      />
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-md-12 text-center'>
                      <button
                        className='btn btn-md w-75 btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#LoginBackgroundModal'
                      >
                        Değiştir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='card card-custom  card-stretch shadow pt-5 mb-5 w-100  h-100 text-center'>
                <h4>Giriş Ekranı Logo Görseli</h4>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <img className='w-100 h-100' src={toAbsoluteUrl('/media/logos/logo.png')} />
                    </div>
                  </div>
                  <div className='row mt-5 h-75'>
                    <div className='col-md-12 text-center'>
                      <button
                        className='btn btn-md w-75  btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#LoginLogoModal'
                      >
                        Değiştir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='card card-custom  card-stretch shadow pt-5 mb-5 w-100  h-100 text-center'>
                <h4>Mobil Logo Görseli</h4>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <img
                        className='w-50h-300px '
                        src={toAbsoluteUrl('/media/logos/logo-2.png')}
                      />
                    </div>
                  </div>
                  <div className='row mt-5 h-75'>
                    <div className='col-md-12 text-center'>
                      <button
                        className='btn btn-md w-75  btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#MobilLogoModal'
                      >
                        Değiştir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='card card-custom  card-stretch shadow pt-5 mb-5 w-100  h-100 text-center'>
                <h4>Favicon Görseli</h4>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <img
                        className='w-50h-300px '
                        src={toAbsoluteUrl('/media/logos/favicon.ico')}
                      />
                    </div>
                  </div>
                  <div className='row mt-5 h-75'>
                    <div className='col-md-12 text-center'>
                      <button
                        className='btn btn-md w-75  btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#FaviconModal'
                      >
                        Değiştir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='LoginBackgroundModal'>
        <div className='modal-dialog '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Dosya Seçin</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body  mb-10 mt-5'>
              <div className='row  mt-5 '>
                <div className='col-md-12 text-center'>
                  <p className='text-muted fs-6'>
                    En iyi görünüm deneyim için seçeceğiniz görselin <strong>1920 X 1080</strong>{' '}
                    çözünürlüğünden düşük olmamasına ve .png gibi yüksek kaliteli dosya formatında
                    olmasına özen gösteriniz. Görselin çözünürlüğü arttıkça görünüm okadar güzel
                    olacaktır.
                  </p>
                </div>
              </div>
              <div className='separator separator-dotted border-dark my-10'></div>
              <div className='row text-center'>
                <div className='col-md-12'>
                  <input
                    type='file'
                    accept='image/*'
                    className='btn btn-secondary w-100'
                    onChange={saveFile}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Kapat
              </button>
              <button
                type='button'
                onClick={() => {
                  uploadFile('LOGIN_BG')
                }}
                className='btn btn-primary'
              >
                {!isLoading && <span>Güncelle</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Lütfen Bekleyin...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='MobilLogoModal'>
        <div className='modal-dialog '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Dosya Seçin</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body  mb-10 mt-5'>
              <div className='row  mt-5 '>
                <div className='col-md-12 text-center'>
                  <p className='text-muted fs-6'>
                    En iyi görünüm deneyim için seçeceğiniz görselin <strong> 30 X 30</strong>{' '}
                    boyutlarından olmasına ve <strong>.png</strong> dosya formatında olmasına özen
                    gösteriniz.
                  </p>
                </div>
              </div>
              <div className='separator separator-dotted border-dark my-10'></div>
              <div className='row text-center'>
                <div className='col-md-12'>
                  <input
                    type='file'
                    accept='image/*'
                    className='btn btn-secondary w-100'
                    onChange={saveFile}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Kapat
              </button>
              <button
                type='button'
                onClick={() => {
                  uploadFile('MOBIL_LG')
                }}
                className='btn btn-primary'
              >
                {!isLoading && <span>Güncelle</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Lütfen Bekleyin...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='LoginLogoModal'>
        <div className='modal-dialog '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Dosya Seçin</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body  mb-10 mt-5'>
              <div className='row  mt-5 '>
                <div className='col-md-12 text-center'>
                  <p className='text-muted fs-6'>
                    En iyi görünüm deneyim için seçeceğiniz görselin <strong> 330 X 70</strong>{' '}
                    boyutlarından olmasına ve <strong>.png</strong> dosya formatında olmasına özen
                    gösteriniz.
                  </p>
                </div>
              </div>
              <div className='separator separator-dotted border-dark my-10'></div>
              <div className='row text-center'>
                <div className='col-md-12'>
                  <input
                    type='file'
                    accept='image/*'
                    className='btn btn-secondary w-100'
                    onChange={saveFile}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Kapat
              </button>
              <button
                type='button'
                onClick={() => {
                  uploadFile('LOGIN_LG')
                }}
                className='btn btn-primary'
              >
                {!isLoading && <span>Güncelle</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Lütfen Bekleyin...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='FaviconModal'>
        <div className='modal-dialog '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Dosya Seçin</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body  mb-10 mt-5'>
              <div className='row  mt-5 '>
                <div className='col-md-12 text-center'>
                  <p className='text-muted fs-6'>
                    Seçeğiniz dosyanın <strong>.ico</strong> formatında olması gerekmektedir.
                  </p>
                </div>
              </div>
              <div className='separator separator-dotted border-dark my-10'></div>
              <div className='row text-center'>
                <div className='col-md-12'>
                  <input
                    type='file'
                    accept='image/*ico'
                    className='btn btn-secondary w-100'
                    onChange={saveFile}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Kapat
              </button>
              <button
                type='button'
                onClick={() => {
                  uploadFile('FAVICON')
                }}
                className='btn btn-primary'
              >
                {!isLoading && <span>Güncelle</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Lütfen Bekleyin...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
