import * as Yup from 'yup'
export const createGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .typeError('Grup Adı zorunludur')
      .min(3, 'Minimum 3 karakter olmalı')
      .required('Grup Adı zorunludur'),
    description: Yup.string()
      .typeError('Açıklama zorunludur')
      .min(3, 'Minimum 3 karakter olmalı')
      .required('Açıklama zorunludur'),
  })
  export const updateGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .typeError('Grup Adı zorunludur')
      .min(3, 'Minimum 3 karakter olmalı')
      .required('Grup Adı zorunludur'),
    description: Yup.string()
      .typeError('Açıklama zorunludur')
      .min(3, 'Minimum 3 karakter olmalı')
      .required('Açıklama zorunludur'),
    isActive: Yup.boolean(),
  })
  export const initialCreateGroupValues = {
    groupName: '',
    description: '',
  }
  export const initialUpdateGroupValues = {
    id: 0,
    groupName: '',
    description: '',
    isActive: false,
    menuItems:[]
  }