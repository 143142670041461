import {DataGrid, GridColDef} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {CreateMenuItem, DeleteMenuItem, GetAllMenuItems, UpdateMenuItem} from './ApiOperations'
import {CreateMenuItemModel, MenuItemModel, UpdateMenuItemModel} from './Models'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import Select from 'react-select'
import {GetIsPermissionGranted} from '../../general/GeneralApiOperations'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
//#endregion

const mySwal = withReactContent(Swal)

const initialCreateMenuItemValues = {
  displayText: '',
  displayTextEN: '',
  menuItemUrl: '',
  icon: '',
  parentMenuId: 0,
  isMain: false,
}
const initialUpdateMenuItemValues = {
  id: 0,
  displayText: '',
  displayTextEN: '',
  menuItemUrl: '',
  icon: '',
  parentMenuId: 0,
  isMain: false,
  isActive: true,
}
const MenuItemManagement = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [isGranted, setisGranted] = useState(false)

  const [menuItems, setMenuItems] = useState<any[]>([])
  const [currentMenuItem, setcurrentMenuItem] = useState<UpdateMenuItemModel>(
    initialUpdateMenuItemValues
  )
  const [isLoading, setLoading] = useState(false)
  const [dropDownMenuItems, setDropDownMenuItems] = useState<any[]>([])
  const [selectedParentMenu, setselectedParentMenu] = useState({label: '', value: 0})
  const [currentMenuItemIsActive, setcurrentMenuItemIsActive] = useState(false)
  const [currentMenuItemIsMain, setcurrentMenuItemIsMain] = useState(false)
//#region Grid Methods
const onGridReady = (params: any) => {
  var gridApi = params.api
  gridApi.sizeColumnsToFit()
}
const EditCellRenderer = (params: any) => {
  const onClick = (e: any) => {
    e.stopPropagation() 
    var menuItem = params.data
    var xMenuItem = {
      id: menuItem.id,
      displayText: menuItem.displayText,
      displayTextEN: menuItem.displayTextEN,
      menuItemUrl: menuItem.menuItemUrl,
      icon: '',
      parentMenuId: menuItem.parentMenuId,
      isMain: menuItem.isMain,
      isActive: menuItem.isActive,
    }
    setcurrentMenuItem(xMenuItem)
    setcurrentMenuItemIsActive(menuItem.isActive)
    setcurrentMenuItemIsMain(menuItem.isMain)
    var parentMenu = menuItems.filter((t) => t.id == menuItem.parentMenuId)[0]
     
    if (parentMenu !== undefined && parentMenu !== null) {
      setselectedParentMenu({label: parentMenu.displayText, value: parentMenu.id})
    } else {
      setselectedParentMenu({label: '', value: 0})
    }
    var xdropDownMenuItems: any[] = []
    menuItems.forEach((ddMenuItem) => {
      xdropDownMenuItems.push({label: ddMenuItem.displayText, value: ddMenuItem.id})
    })
    var ydropDownMenuItems = xdropDownMenuItems.filter((d) => d.value !== menuItem.id)
    setDropDownMenuItems(ydropDownMenuItems)
  }

  return (
    <button
      data-bs-toggle='modal'
      data-bs-target='#UpdateMenuItemModal'
      className='btn btn-sm btn-info w-100'
      onClick={onClick}
    >
      {!isLoading && <span>Düzenle</span>}

      {isLoading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Lütfen Bekleyin...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}
const DeleteCellRenderer = (params: any) => {
  const onClick = (e: any) => {
    e.stopPropagation()
    setLoading(true)
  
    var menuItem = params.data
    mySwal
      .fire({
        title: 'Onay',
        icon: 'info',
        text: `${menuItem.displayText}  adlı menüyü silmek istediğinize emin misiniz ?`,
        showDenyButton: true,
        confirmButtonText: 'Sil',
        denyButtonText: `Vazgeç`,
      })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteMenuItem(menuItem.id)
            .then((response) => {
              mySwal
                .fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success')
                .then(() => {
                  GetData()
                  setLoading(false)
                })
            })
            .catch(() => {
              mySwal.fire('Hata !', '<p>Bir hata oluştu</p>', 'error').then(() => {
                setLoading(false)
              })
            })
        }
        setLoading(false)
      })
  }

  return (
    <button className='btn btn-sm btn-danger w-100' onClick={onClick}>
      {!isLoading && <span className='indicator-label'>Sil</span>}
      {isLoading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Lütfen Bekleyin...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}
const IsActiveCellRenderer = (params: any) => {
  var isActive = params.data.isActive;
  return (
    <div className='mb-0'>
      <div className='form-check form-check-custom form-check-solid'>
        {isActive ? (
          <input
            className='form-check-input mt-2'
            type='checkbox'
            disabled
            value=''
            checked
          />
        ) : (
          <input className='form-check-input mt-2' type='checkbox' disabled value='' />
        )}
      </div>
    </div>
  )
}
const IsMainCellRenderer = (params: any) => {
  var isMain = params.data.isMain;
  return (
    <div className='mb-0'>
      <div className='form-check form-check-custom form-check-solid'>
        {isMain ? (
          <input
            className='form-check-input mt-2'
            type='checkbox'
            disabled
            value=''
            checked
          />
        ) : (
          <input className='form-check-input mt-2' type='checkbox' disabled value='' />
        )}
      </div>
    </div>
  )
}
//#endregion

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'ID',  headerAlign: 'center',
    headerClassName: 'fs-5 text-primary  border border-left-3', width: 200, hide: true},
    {field: 'displayText', headerName: 'Menü Adı',  headerAlign: 'center',
    headerClassName: 'fs-5 text-primary  ', width: 200},
    {field: 'displayTextEN', headerName: 'Menü Adı_EN', headerAlign: 'center',
    headerClassName: 'fs-5 text-primary  border border-left-3', width: 200},
    {field: 'menuItemUrl', headerName: 'Url',  headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3', width: 200},
    {field: 'icon', headerName: 'Icon',  headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3', width: 200},
    {
      field: 'parentMenuId',
      headerName: 'Ana Menu ID',
       headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3',
      width: 100,
      hide: true,
    },
    {field: 'parentMenuName', headerName: 'Üst Menu Adı',  headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3', width: 200},
    {
      field: 'isMain',
      headerName: 'Ana Menü ?',
       headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3',
      width: 200,
      renderCell: (params) => {
        var isMain = params.getValue(params.id, 'isMain')
        return (
          <div className='mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              {isMain ? (
                <input
                  className='form-check-input mt-10'
                  type='checkbox'
                  disabled
                  value=''
                  checked
                />
              ) : (
                <input className='form-check-input mt-10' type='checkbox' disabled value='' />
              )}
            </div>
          </div>
        )
      },
    },
    {field: 'createdByStr', headerName: 'Oluşturan',  headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3', width: 200},
    {field: 'createdDateStr', headerName: 'Oluşturma Tarihi',  headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3', width: 150},
    {
      field: 'isActive',
      headerName: 'Aktif mi',
      type: 'boolean',
       headerAlign: 'center',
      headerClassName: 'fs-5 text-primary  border border-left-3',
      width: 150,
      renderCell: (params) => {
        var isActive = params.getValue(params.id, 'isActive')
        return (
          <div className='mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              {isActive ? (
                <input
                  className='form-check-input mt-10'
                  type='checkbox'
                  disabled
                  value=''
                  checked
                />
              ) : (
                <input className='form-check-input mt-10' type='checkbox' disabled value='' />
              )}
            </div>
          </div>
        )
      },
    }
  ]

  const GetData = () => {
    GetAllMenuItems()
      .then((response) => {
        var menuItems = response.data
        setMenuItems(menuItems)
        var dropDownMenuItems: any[] = []
        menuItems.forEach((ddMenuItem) => {
          dropDownMenuItems.push({label: ddMenuItem.displayText, value: ddMenuItem.id})
        })
        setDropDownMenuItems(dropDownMenuItems)
      })
      .catch(() => {
        alert('Bir hata oluştu')
      })
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/menu-item-management').then((response) => {
        var isGranted = response.data

        setisGranted(isGranted)
         
        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
      GetData()
    }
    return () => {
      isMounted = false
    }
  }, [])
  const createMenuItemSchema = Yup.object().shape({
    displayText: Yup.string().typeError('Menü adı zorunludur').required('Menü adı zorunludur'),
    displayTextEN: Yup.string()
      .typeError('Menü adı EN zorunludur')
      .required('Menü adı EN zorunludur'),
    menuItemUrl: Yup.string()
      .typeError('URL zorunludur')
      .min(1, 'Minimum 1 karakter olmalı')
      .required('URL zorunludur'),
    parentMenuId: Yup.number(),
    isMain: Yup.boolean(),
  })
  const updateMenuItemSchema = Yup.object().shape({
    displayText: Yup.string()
      .typeError('Görünüm adı zorunludur')
      .required('Görünüm adı zorunludur'),
    displayTextEN: Yup.string()
      .typeError('Görünüm adı EN zorunludur')
      .required('Görünüm adı EN zorunludur'),
    menuItemUrl: Yup.string()
      .typeError('URL zorunludur')
      .min(1, 'Minimum 1 karakter olmalı')
      .required('URL zorunludur'),
    parentMenuId: Yup.number(),
    isMain: Yup.boolean(),
    isActive: Yup.boolean(),
  })
  const CreateMenuItemFormik = useFormik({
    initialValues: initialCreateMenuItemValues,
    validationSchema: createMenuItemSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
       
      values.parentMenuId = selectedParentMenu.value
      setLoading(true)
      setTimeout(() => {
        CreateMenuItem(values as CreateMenuItemModel)
          .then((reponse) => {
            mySwal.fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            console.log(error.response.data)
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              'Hata !',
              '<p>Bir hata oluştu. Lütfen daha sonra tekrar deneyin.</p>',
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })
  const UpdateMenuItemFormik = useFormik({
    enableReinitialize: true,
    initialValues: currentMenuItem,
    validationSchema: updateMenuItemSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
       
      values.isActive = currentMenuItemIsActive
      values.parentMenuId = selectedParentMenu.value
      values.isMain = currentMenuItemIsMain
      setLoading(true)
      setTimeout(() => {
        UpdateMenuItem(values as UpdateMenuItemModel)
          .then((reponse) => {
            mySwal.fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            console.log(error.response.data)
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              'Hata !',
              '<p>Bir hata oluştu. Lütfen daha sonra tekrar deneyin.</p>',
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })
  return (
    <>
      {isGranted ? (
        <>
          <>
            <div className='row alert alert-primary d-flex align-items-center pb-10 text-center'>
              <div className='col-md-10 text-center'>
                <div className='d-flex flex-column'>
                  <h5 className='mb-1'> Menü Yönetimi</h5>
                  <span>
                    Bu ekranı kullanarak yeni menü ekleyebilir , var olan menüleri düzenleyebilir veya silebilirsiniz.
                  </span>
                </div>
              </div>
              <div className='col-md-2'>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#CreateMenuItemModal'
                  className='btn btn-md btn-primary float-right w-100 mt-5'
                  onClick={() => {
                    GetData()
                  }}
                >
                  <i className='fa fa-plus' aria-hidden='true'></i>
                  Yeni Menu Item
                </button>
              </div>
            </div>
            <div className='card card-custom  card-stretch shadow '>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                   
                  <div className='ag-theme-alpine' style={{height: 600, width: '100%'}}>
                      <AgGridReact
                        frameworkComponents={{
                          isMainCellRenderer: IsMainCellRenderer,
                          isActiveCellRenderer:IsActiveCellRenderer,
                          editCellRenderer:EditCellRenderer,
                          deleteCellRenderer:DeleteCellRenderer
                        }}
                        defaultColDef={{
                          
                          sortable: true,
                          flex: 1,
                          filter: true,
                          resizable: true,
                        }}
                        onGridReady={onGridReady}
                        rowData={menuItems}
                      >
                        <AgGridColumn field='id' hide={true} />
                        <AgGridColumn field='parentMenuId' hide={true} />
                       
                        <AgGridColumn field='displayText' headerName="Menü Adı"  />
                        <AgGridColumn field='displayTextEN' headerName="Menü Adı EN"  />
                        <AgGridColumn field='parentMenuName' headerName="Üst Menü Adı"  />
                        <AgGridColumn field='menuItemUrl' headerName="URL"  />
                        <AgGridColumn field='icon' headerName="Icon"  />

                        <AgGridColumn field='isMain' headerName="Ana Menü ?" width={150} cellRenderer='isMainCellRenderer' />
                        <AgGridColumn field='createdByStr' headerName="Oluşturan"  />
                        <AgGridColumn field='createdDateStr' headerName="Oluşturma Tarihi"  />
                        <AgGridColumn field='isActive' headerName="Aktif Mi ?" width={150} cellRenderer='isActiveCellRenderer' />
                        <AgGridColumn field='düzenle' pinned="right" width={150} headerName="Düzenle" cellRenderer='editCellRenderer' />
                        <AgGridColumn field='sil' pinned="right" width={100} headerName="Sil" cellRenderer='deleteCellRenderer' />
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal fade' tabIndex={-1} id='CreateMenuItemModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form
                    className='form w-100'
                    onSubmit={CreateMenuItemFormik.handleSubmit}
                    noValidate
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title'>Yeni Menu Item</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Menü Adı :</label>
                        <input
                          placeholder='Lütfen menü adı giriniz...'
                          {...CreateMenuItemFormik.getFieldProps('displayText')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateMenuItemFormik.touched.displayText &&
                                CreateMenuItemFormik.errors.displayText,
                            },
                            {
                              'is-valid':
                                CreateMenuItemFormik.touched.displayText &&
                                !CreateMenuItemFormik.errors.displayText,
                            }
                          )}
                          type='text'
                          name='displayText'
                          autoComplete='off'
                        />
                        {CreateMenuItemFormik.touched.displayText &&
                          CreateMenuItemFormik.errors.displayText && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateMenuItemFormik.errors.displayText}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Menü Adı_EN :</label>
                        <input
                          placeholder='Lütfen menü adı en giriniz...'
                          {...CreateMenuItemFormik.getFieldProps('displayTextEN')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateMenuItemFormik.touched.displayTextEN &&
                                CreateMenuItemFormik.errors.displayTextEN,
                            },
                            {
                              'is-valid':
                                CreateMenuItemFormik.touched.displayTextEN &&
                                !CreateMenuItemFormik.errors.displayTextEN,
                            }
                          )}
                          type='text'
                          name='displayTextEN'
                          autoComplete='off'
                        />
                        {CreateMenuItemFormik.touched.displayTextEN &&
                          CreateMenuItemFormik.errors.displayTextEN && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateMenuItemFormik.errors.displayTextEN}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>URL :</label>
                        <input
                          placeholder='Lütfen url giriniz...'
                          {...CreateMenuItemFormik.getFieldProps('menuItemUrl')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateMenuItemFormik.touched.menuItemUrl &&
                                CreateMenuItemFormik.errors.menuItemUrl,
                            },
                            {
                              'is-valid':
                                CreateMenuItemFormik.touched.menuItemUrl &&
                                !CreateMenuItemFormik.errors.menuItemUrl,
                            }
                          )}
                          type='text'
                          name='menuItemUrl'
                          autoComplete='off'
                        />
                        {CreateMenuItemFormik.touched.menuItemUrl &&
                          CreateMenuItemFormik.errors.menuItemUrl && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateMenuItemFormik.errors.menuItemUrl}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Üst Menu :</label>
                        <Select
                          {...CreateMenuItemFormik.getFieldProps('parentMenuId')}
                          className={clsx(
                            '',
                            {
                              'is-invalid':
                                CreateMenuItemFormik.touched.parentMenuId &&
                                CreateMenuItemFormik.errors.parentMenuId,
                            },
                            {
                              'is-valid':
                                CreateMenuItemFormik.touched.parentMenuId &&
                                !CreateMenuItemFormik.errors.parentMenuId,
                            }
                          )}
                          placeholder='Buradan eklenecek menünün ait olduğu ana menüyü seçebilirsiniz..'
                          options={dropDownMenuItems}
                          value={selectedParentMenu}
                          onChange={(e) => {
                            setselectedParentMenu(e as any)
                          }}
                        />
                        {CreateMenuItemFormik.touched.parentMenuId &&
                          CreateMenuItemFormik.errors.parentMenuId && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateMenuItemFormik.errors.parentMenuId}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='row mb-10 align-items-center'>
                        <div className='col-sm-2  p-0'>
                          <p className='fs-6 fw-bolder '>Ana Menü ?: </p>
                        </div>
                        <div className='col-sm-1  p-0'>
                          <div className='form-check form-check-custom form-check-solid '>
                            {
                              <input
                                placeholder='Opsiyonel'
                                {...CreateMenuItemFormik.getFieldProps('isMain')}
                                className='form-check-input '
                                type='checkbox'
                                name='isMain'
                              />
                            }
                          </div>
                        </div>
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>Oluştur</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Lütfen Bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='UpdateMenuItemModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form
                    className='form w-100'
                    onSubmit={UpdateMenuItemFormik.handleSubmit}
                    noValidate
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title'>Menu Item Güncelle</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Menü Adı :</label>
                        <input
                          placeholder='Lütfen menü adı giriniz...'
                          {...UpdateMenuItemFormik.getFieldProps('displayText')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateMenuItemFormik.touched.displayText &&
                                UpdateMenuItemFormik.errors.displayText,
                            },
                            {
                              'is-valid':
                                UpdateMenuItemFormik.touched.displayText &&
                                !UpdateMenuItemFormik.errors.displayText,
                            }
                          )}
                          type='text'
                          name='displayText'
                          autoComplete='off'
                        />
                        {UpdateMenuItemFormik.touched.displayText &&
                          UpdateMenuItemFormik.errors.displayText && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateMenuItemFormik.errors.displayText}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Menü Adı_EN :</label>
                        <input
                          placeholder='Lütfen menü adı en giriniz...'
                          {...UpdateMenuItemFormik.getFieldProps('displayTextEN')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateMenuItemFormik.touched.displayTextEN &&
                                UpdateMenuItemFormik.errors.displayTextEN,
                            },
                            {
                              'is-valid':
                                UpdateMenuItemFormik.touched.displayTextEN &&
                                !UpdateMenuItemFormik.errors.displayTextEN,
                            }
                          )}
                          type='text'
                          name='displayTextEN'
                          autoComplete='off'
                        />
                        {UpdateMenuItemFormik.touched.displayTextEN &&
                          UpdateMenuItemFormik.errors.displayTextEN && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateMenuItemFormik.errors.displayTextEN}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>URL :</label>
                        <input
                          placeholder='Lütfen url giriniz...'
                          {...UpdateMenuItemFormik.getFieldProps('menuItemUrl')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateMenuItemFormik.touched.menuItemUrl &&
                                UpdateMenuItemFormik.errors.menuItemUrl,
                            },
                            {
                              'is-valid':
                                UpdateMenuItemFormik.touched.menuItemUrl &&
                                !UpdateMenuItemFormik.errors.menuItemUrl,
                            }
                          )}
                          type='text'
                          name='menuItemUrl'
                          autoComplete='off'
                        />
                        {UpdateMenuItemFormik.touched.menuItemUrl &&
                          UpdateMenuItemFormik.errors.menuItemUrl && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateMenuItemFormik.errors.menuItemUrl}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Üst Menu :</label>
                        <Select
                          {...UpdateMenuItemFormik.getFieldProps('parentMenuId')}
                          className={clsx(
                            '',
                            {
                              'is-invalid':
                                UpdateMenuItemFormik.touched.parentMenuId &&
                                UpdateMenuItemFormik.errors.parentMenuId,
                            },
                            {
                              'is-valid':
                                UpdateMenuItemFormik.touched.parentMenuId &&
                                !UpdateMenuItemFormik.errors.parentMenuId,
                            }
                          )}
                          placeholder='Buradan eklenecek menünün ait olduğu ana menüyü seçebilirsiniz..'
                          options={dropDownMenuItems}
                          value={selectedParentMenu}
                          onChange={(e) => {
                            setselectedParentMenu(e as any)
                          }}
                        />
                        {UpdateMenuItemFormik.touched.parentMenuId &&
                          UpdateMenuItemFormik.errors.parentMenuId && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateMenuItemFormik.errors.parentMenuId}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='row mb-10'>
                        <div className='col-sm-2  p-0'>
                          <p className='fs-6 fw-bolder '>Ana Menü ?: </p>
                        </div>
                        <div className='col-sm-1  p-0'>
                          <div className='form-check form-check-custom form-check-solid '>
                            {
                              <input
                                placeholder='Opsiyonel'
                                {...UpdateMenuItemFormik.getFieldProps('isMain')}
                                className='form-check-input '
                                type='checkbox'
                                onChange={(e) => {
                                  setcurrentMenuItemIsMain(e.target.checked)
                                }}
                                checked={currentMenuItemIsMain}
                                name='isMain'
                              />
                            }
                          </div>
                        </div>
                        <div className='col-sm-2  p-0'>
                          <p className='fs-6 fw-bolder '>Aktif Mi ? : </p>
                        </div>
                        <div className='col-sm-1  p-0'>
                          <div className='form-check form-check-custom form-check-solid '>
                            {
                              <input
                                {...UpdateMenuItemFormik.getFieldProps('isActive')}
                                className='form-check-input'
                                type='checkbox'
                                name='isActive'
                                checked={currentMenuItemIsActive}
                                onChange={(e) => {
                                  setcurrentMenuItemIsActive(e.target.checked)
                                }}
                              />
                            }
                          </div>
                        </div>
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>Güncelle</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Lütfen Bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MenuItemManagement
