//#region imports

import {DataGrid, GridColDef} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import withReactContent from 'sweetalert2-react-content'
import {RootState} from '../../../../setup'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {
  CreateUser,
  DeleteUser,
  GetAllGroups,
  GetAllMenuItems,
  GetAllUsers,
  GetCustomers,
  GetUserTypes,
  UpdateUser,
  UpdateUserGroups,
  UpdateUserMenuItemRights,
} from './ApiOperations'
import {
  CreateUserModel,
  CustomerModel,
  GroupModel,
  MenuItemModel,
  UpdateUserGroupsModel,
  UpdateUserMenuItemRightModel,
  UpdateUserModel,
  UserTypeModel,
} from './Models'
import {useFormik} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import Select from 'react-select'
import CheckboxTree, {Node} from 'react-checkbox-tree'
import {GetIsPermissionGranted, IsRestUser} from '../../general/GeneralApiOperations'

import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import InputMask from 'react-input-mask'
const mySwal = withReactContent(Swal)

//#endregion

//#region initial values and deafults
const initialCreateUserValues = {
  customerId: 0,
  userTypeId: 0,
  title: '',
  name: '',
  surname: '',
  email: '',
  phone: '',
  password: '',
  passwordAgain: '',
}
const initialUpdateUserValues = {
  id: 0,
  customerId: 0,
  userTypeId: 0,
  title: '',
  name: '',
  surname: '',
  email: '',
  phone: '',
  isActive: false,
  menuItems: [],
  groups: [],
}

let menuItems: MenuItemModel[] = []

//#endregion

const UserManagement = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  //#region states
  const [isGranted, setisGranted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [tableRows, setTableRows] = useState<any[]>([])
  const [currentUser, setCurrentUser] = useState<UpdateUserModel>(initialUpdateUserValues)
  const [userTypes, setuserTypes] = useState<Array<any>>([])
  const [customerOptions, setCustomerOptions] = useState<Array<any>>([])
  const [selectedCustomer, setSelectedCustomer] = useState({label: '', value: 0})
  const [selectedUserType, setSelectedUserType] = useState({label: '', value: 0})
  const [currentUserIsActive, setcurrentUserIsActive] = useState(false)
  const [groups, setGroups] = useState<GroupModel[]>([])
  const [currentUserGroups, setcurrentUserGroups] = useState<string[]>([])
  const [checkedAuths, setcheckedAuths] = useState<string[]>([])
  const [expandedAuths, setexpandedAuths] = useState<string[]>([])
  const [authTreeNodes, setauthTreeNodes] = useState<Node[]>([])
  //#endregion
  //#region Grid Methods
  const onGridReady = (params: any) => {
    const allColumnIds: any = []
    var gridColumnApi = params.columnApi
    gridColumnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId)
    })
    gridColumnApi.autoSizeColumns(allColumnIds, false)
  }
  const EditCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation() // don't select this row after clicking

      var user = params.data
      var userType = {label: user.userTypeName, value: user.userTypeId}
      var customer = {label: user.customerName, value: user.customerId}
      setcurrentUserIsActive(user.isActive)
      setSelectedUserType(userType)
      setSelectedCustomer(customer)
      setCurrentUser(user)
      console.log(currentUser)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#UpdateUserModal'
        className='btn btn-sm btn-info w-100'
        onClick={onClick}
      >
        {!isLoading && <span>Düzenle</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Lütfen Bekleyin...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const AuthsCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation()

      var user = params.data
      setCurrentUser(user)
      setcheckedAuths(user.menuItems)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#UserAuthModal'
        className='btn btn-sm btn-success w-100'
        onClick={onClick}
      >
        {!isLoading && <span>Yetkiler</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Lütfen Bekleyin...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const GroupCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation() // don't select this row after clicking

      var user = params.data
      setCurrentUser(user)
      setcheckedAuths(user.menuItems)
      setcurrentUserGroups(user.groups)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#GroupModal'
        className='btn btn-sm btn-warning w-100'
        onClick={onClick}
      >
        {!isLoading && <span>Gruplar</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Lütfen Bekleyin...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const DeleteCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation()
      setLoading(true)

      var xuser = params.data
      var isRestUser = false
      IsRestUser(user.email).then((response) => {
        debugger
        var value = response.data
        isRestUser = value

        if (user.id == xuser.id) {
          mySwal
            .fire('Bilgilendirme', 'Aktif kullanıcı kendi kendini silemez ', 'info')
            .then(() => {
              setLoading(false)
            })
        } else if (
          tableRows?.length !== 1 &&
          tableRows[0]?.id !== xuser.id &&
          xuser.email != 'info@restyazilim.com'
        ) {
          mySwal
            .fire({
              title: 'Onay',
              icon: 'info',
              text: `${xuser.name} ${xuser.surname} kullanıcısını silmek istediğinize emin misiniz ?`,
              showDenyButton: true,
              confirmButtonText: 'Sil',
              denyButtonText: `Vazgeç`,
            })
            .then((result) => {
              if (result.isConfirmed) {
                DeleteUser(xuser.id)
                  .then((response) => {
                    mySwal
                      .fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success')
                      .then(() => {
                        GetData()
                        setLoading(false)
                      })
                  })
                  .catch(() => {
                    mySwal.fire('Hata !', '<p>Bir hata oluştu</p>', 'error').then(() => {
                      setLoading(false)
                    })
                  })
              }
              setLoading(false)
            })
        } else {
          mySwal
            .fire('Bilgilendirme', 'Var olan tek kullanıcıyı silemezsiniz.', 'info')
            .then(() => {
              setLoading(false)
            })
        }
      })
    }

    return (
      <button className='btn btn-sm btn-danger w-100' onClick={onClick}>
        {!isLoading && <span className='indicator-label'>Sil</span>}
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Lütfen Bekleyin...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const IsActiveCellRenderer = (params: any) => {
    var isActive = params.data.isActive
    return (
      <div className='mb-0'>
        <div className='form-check form-check-custom form-check-solid'>
          {isActive ? (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' checked />
          ) : (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' />
          )}
        </div>
      </div>
    )
  }
  //#endregion

  //#region methods
  const GetAuthTreeViewList = (list: any[], isChild: boolean = false) => {
    if (list) {
      list = list.sort((a, b) => {
        if (a.isMain > b.isMain) {
          return -1
        }
        if (a.isMain < b.isMain) {
          return 1
        }
        return 0
      })
      let newList: Node[] = []
      for (var i = 0; i < list.length; i++) {
        var childrens = menuItems.filter((m) => m.parentMenuId == list[i].id)
        var parentCount = menuItems.filter((x) => x.id === list[i].parentMenuId).length
        if (
          (list[i].parentMenuId === 0 && childrens.length > 0) ||
          (list[i].parentMenuId !== 0 && childrens.length > 0 && parentCount === 0) ||
          (list[i].parentMenuId !== 0 && childrens.length > 0 && isChild)
        ) {
          var parentNode: Node = {
            label: list[i].displayText,
            value: list[i].id.toString(),
            children: [],
          }
          parentNode.children?.splice(0, 1)

          let returnedChildrens = GetAuthTreeViewList(childrens, true)
          returnedChildrens?.forEach((children) => {
            parentNode.children?.push(children)
          })
          newList.push(parentNode)
        } else if (list[i].parentMenuId === 0) {
          var parentNode: Node = {
            label: list[i].displayText,
            value: list[i].id.toString(),
          }
          newList.push(parentNode)
        } else if (isChild) {
          var childNode: Node = {
            label: list[i].displayText,
            value: list[i].id.toString(),
          }
          newList.push(childNode)
        }
      }
      return newList
    }
  }
  const UpdateUserGroupsByUserId = (model: UpdateUserGroupsModel) => {
    UpdateUserGroups(model)
      .then(() => {
        mySwal.fire('Başarılı !', 'Güncelleme başarıyla tamamlandı.', 'success').then(() => {
          GetData()
          setLoading(false)
          window.location.reload()
        })
      })
      .catch(() => {
        mySwal
          .fire('Hata !', 'Bir hata oluştu , lütfen daha sonra tekrar deneyin', 'error')
          .then(() => {
            GetData()
            setLoading(false)
          })
      })
  }
  const UpdateUserMenuItemRightsByGroup = (model: UpdateUserMenuItemRightModel) => {
    UpdateUserMenuItemRights(model)
      .then(() => {
        mySwal.fire('Başarılı !', 'Güncelleme başarıyla tamamlandı.', 'success').then(() => {
          GetData()
          setLoading(false)
          window.location.reload()
        })
      })
      .catch(() => {
        mySwal
          .fire('Hata !', 'Bir hata oluştu , lütfen daha sonra tekrar deneyin', 'error')
          .then(() => {
            GetData()
            setLoading(false)
          })
      })
  }
  const GetGroups = () => {
    GetAllGroups()
      .then((response) => {
        var groups = response.data
        setGroups(groups)
      })
      .catch(() => {
        alert('Gruplar Çekilirken bir hata oluştu')
      })
  }
  const GetMenuItems = () => {
    GetAllMenuItems()
      .then((response) => {
        menuItems = []
        var xmenuItems = response.data
        xmenuItems.forEach((xmenuItem) => {
          menuItems.push(xmenuItem)
        })
        var treeNodes = GetAuthTreeViewList(xmenuItems)

        setauthTreeNodes(treeNodes!)
      })
      .catch(() => {
        alert('Menüler çekilirken bir hata oluştu')
      })
  }
  const GetData = () => {
    GetAllUsers()
      .then((response) => {
        var users = response.data
        setTableRows(users)
        GetMenuItems()
      })
      .catch((error) => {
        alert('Kullanıcılar çekilirken bir hata oluştu.' + error.response)
      })
  }
  const FillUserTypes = () => {
    GetUserTypes()
      .then((response) => {
        var types = response.data
        var typeOpts: Array<any> = []
        types.forEach((type) => {
          typeOpts.push({label: type.typeName, value: type.id})
        })
        setuserTypes(typeOpts)
      })
      .catch(() => {
        alert('Kullanıcı tipleri çekilirken bir hata oluştu.')
      })
    setuserTypes([])
  }
  const FillCustomers = () => {
    GetCustomers()
      .then((response) => {
        var customers = response.data
        var customerOpts: Array<any> = []
        customers.forEach((customer) => {
          customerOpts.push({label: customer.title, value: customer.id})
        })

        setCustomerOptions(customerOpts)
      })
      .catch(() => {
        alert('Müşteriler çekilirken bir hata oluştu.')
      })
  }
  const HandleGroupChange = (id: string, checked: boolean) => {
    var currGroups = currentUserGroups
    if (checked && !currentUserGroups.includes(id)) {
      currGroups = [...currGroups, id]
    } else {
      currGroups = currGroups.filter((x) => x !== id)
    }

    setcurrentUserGroups(currGroups)
  }
  //#endregion

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/user-management').then((response) => {
        var isGranted = response.data
        setisGranted(isGranted)

        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })

      GetData()
      FillUserTypes()
      FillCustomers()
      GetGroups()
    }
    return () => {
      isMounted = false
    }
  }, [])

  //#region forms
  const createUserSchema = Yup.object().shape({
    customerId: Yup.number(),
    userTypeId: Yup.number(),
    name: Yup.string()
      .typeError('Ad zorunludur')
      .min(1, 'Minimum 1 karakter olmalı')
      .required('Ad zorunludur'),
    surname: Yup.string()
      .typeError('Soyad zorunludur')
      .min(1, 'Minimum 1 karakter olmalı')
      .required('Soyad zorunludur'),
    title: Yup.string()
      .typeError('Unvan zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Unvan zorunludur'),
    password: Yup.string()
      .typeError('Şifre zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Şifre zorunludur'),
    passwordAgain: Yup.string()
      .typeError('Şifre zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Şifre zorunludur'),
    phone: Yup.string()
      .typeError('Telefon zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Telefon zorunludur'),
    email: Yup.string()
      .typeError('Email zorunludur')
      .email('Email formatı yanlış')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Email zorunludur'),
    isActive: Yup.boolean(),
  })
  const updateUserSchema = Yup.object().shape({
    customerId: Yup.number(),
    userTypeId: Yup.number(),
    name: Yup.string()
      .typeError('Ad zorunludur')
      .min(1, 'Minimum 1 karakter olmalı')
      .required('Ad zorunludur'),
    surname: Yup.string()
      .typeError('Soyad zorunludur')
      .min(1, 'Minimum 1 karakter olmalı')
      .required('Soyad zorunludur'),
    title: Yup.string()
      .typeError('Unvan zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Unvan zorunludur'),
    phone: Yup.string()
      .typeError('Telefon zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Telefon zorunludur'),
    email: Yup.string()
      .typeError('Email zorunludur')
      .email('Email formatı yanlış')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Email zorunludur'),
    isActive: Yup.boolean(),
  })
  const CreateUserFormik = useFormik({
    initialValues: initialCreateUserValues,
    validationSchema: createUserSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      if (selectedUserType.value === 0) {
        mySwal.fire('Bilgilendirme !', 'Lütfen bir kullanıcı tipi seçiniz.', 'info').then(() => {})
      } else if (selectedCustomer.value === 0) {
        mySwal.fire('Bilgilendirme !', 'Lütfen bir müşteri seçiniz.', 'info').then(() => {})
      } else {
        if (values.password !== values.passwordAgain) {
          mySwal.fire(
            'Bilgilendirme',
            'Girdiğiniz şifreler eşleşmiyor. Lütfen kontrol ediniz.',
            'info'
          )
          return
        }

        values.userTypeId = selectedUserType.value
        values.customerId = selectedCustomer.value
        setLoading(true)
        setTimeout(() => {
          CreateUser(values as CreateUserModel)
            .then((reponse) => {
              mySwal
                .fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success')
                .then(() => {
                  GetData()
                  resetForm()
                  setLoading(false)
                  window.location.reload()
                })
            })
            .catch((error) => {
              setLoading(false)
              setSubmitting(false)
              mySwal.fire(
                'Hata !',
                '<p>Bir hata oluştu. Girdiğiniz mailin farklı bir kullanıcı tarafından kullanılmadığına emin olduktan sonra tekrar deneyin.</p>',
                'error'
              )
              setStatus(error.message)
            })
        }, 1000)
      }
    },
  })
  const UpdateUserFormik = useFormik({
    enableReinitialize: true,
    initialValues: currentUser,
    validationSchema: updateUserSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      if (selectedUserType.value === 0) {
        mySwal.fire('Bilgilendirme !', 'Lütfen bir kullanıcı tipi seçiniz.', 'info').then(() => {})
      } else if (selectedCustomer.value === 0) {
        mySwal.fire('Bilgilendirme !', 'Lütfen bir müşteri seçiniz.', 'info').then(() => {})
      } else {
        values.userTypeId = selectedUserType.value
        values.customerId = selectedCustomer.value
        values.isActive = currentUserIsActive
        setLoading(true)
        setTimeout(() => {
          UpdateUser(values as UpdateUserModel)
            .then((reponse) => {
              mySwal
                .fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success')
                .then(() => {
                  GetData()
                  resetForm()
                  setLoading(false)
                  window.location.reload()
                })
            })
            .catch((error) => {
              console.log(error.response.data)
              setLoading(false)
              setSubmitting(false)
              mySwal.fire(
                'Hata !',
                '<p>Bir hata oluştu. Lütfen daha sonra tekrar deneyin.</p>',
                'error'
              )
              setStatus(error.message)
            })
        }, 1000)
      }
    },
  })
  //#endregion

  return (
    <>
      {isGranted ? (
        <>
          <>
            <div className='row  alert alert-primary d-flex align-items-center pb-10 text-center'>
              <div className='col-md-10'>
                <div className='d-flex flex-column'>
                  <h5 className='mb-1'> Kullanıcı Yönetimi</h5>
                  <span>
                    Bu ekranı kullanarak yeni kullanıcı ekleyebilir , var olan kullanıcıları
                    düzenleyebilir veya silebilirsiniz.
                  </span>
                </div>
              </div>
              <div className='col-md-2'>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#NewUserModal'
                  className='btn btn-md mt-5 btn-primary float-right w-100'
                  onClick={() => {
                    setSelectedCustomer({label: '', value: 0})
                    setSelectedUserType({label: '', value: 0})
                  }}
                >
                  <i className='fa fa-plus' aria-hidden='true'></i>
                  Yeni Kullanıcı
                </button>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='ag-theme-alpine' style={{height: 600, width: '100%'}}>
                      <AgGridReact
                        frameworkComponents={{
                          groupCellRenderer: GroupCellRenderer,
                          authCellRenderer: AuthsCellRenderer,
                          isActiveCellRenderer: IsActiveCellRenderer,
                          editCellRenderer: EditCellRenderer,
                          deleteCellRenderer: DeleteCellRenderer,
                        }}
                        defaultColDef={{
                          sortable: true,
                          flex: 1,
                          filter: true,
                          resizable: true,
                          suppressAutoSize: true,
                        }}
                        onGridReady={onGridReady}
                        rowData={tableRows}
                      >
                        <AgGridColumn field='id' hide={true} />
                        <AgGridColumn field='userTypeId' hide={true} />
                        <AgGridColumn field='customerId' hide={true} />
                        <AgGridColumn field='menuItems' hide={true} />
                        <AgGridColumn field='groups' hide={true} />

                        <AgGridColumn field='name' width={300} headerName='Ad' />
                        <AgGridColumn field='surname' headerName='Soyad' />
                        <AgGridColumn field='title' headerName='Ünvan' />
                        <AgGridColumn field='email' headerName='Email' />
                        <AgGridColumn field='phone' headerName='Telefon' />
                        <AgGridColumn field='customerName' headerName='Müşteri Adı' />
                        <AgGridColumn field='userTypeName' headerName='Kullanıcı Tipi' />

                        <AgGridColumn field='createdByStr' headerName='Oluşturan' />
                        <AgGridColumn field='createdDateStr' headerName='Oluşturma Tarihi' />
                        <AgGridColumn
                          field='isActive'
                          headerName='Aktif Mi ?'
                          suppressSizeToFit={true}
                          width={150}
                          cellRenderer='isActiveCellRenderer'
                        />
                        <AgGridColumn
                          field='groupManagement'
                          pinned='right'
                          resizable={false}
                          headerName='Gruplar'
                          width={125}
                          cellRenderer='groupCellRenderer'
                        />
                        <AgGridColumn
                          field='yetkiler'
                          pinned='right'
                          resizable={false}
                          headerName='Yetkiler'
                          width={125}
                          cellRenderer='authCellRenderer'
                        />
                        <AgGridColumn
                          field='düzenle'
                          pinned='right'
                          resizable={false}
                          width={125}
                          headerName='Düzenle'
                          cellRenderer='editCellRenderer'
                        />
                        <AgGridColumn
                          field='sil'
                          pinned='right'
                          resizable={false}
                          width={100}
                          headerName='Sil'
                          cellRenderer='deleteCellRenderer'
                        />
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='UpdateUserModal'>
              <div className='modal-dialog   modal-xl'>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={UpdateUserFormik.handleSubmit}>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Kullanıcı Güncelle</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      <div data-scroll='true' data-height='300'>
                        {/* begin::Form group */}
                        <div className='fv-row mb-10'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Ünvan :</label>
                          <input
                            placeholder='Lütfen müşteri ünvanı giriniz...'
                            {...UpdateUserFormik.getFieldProps('title')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  UpdateUserFormik.touched.title && UpdateUserFormik.errors.title,
                              },
                              {
                                'is-valid':
                                  UpdateUserFormik.touched.title && !UpdateUserFormik.errors.title,
                              }
                            )}
                            type='text'
                            name='title'
                            autoComplete='off'
                          />
                          {UpdateUserFormik.touched.title && UpdateUserFormik.errors.title && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateUserFormik.errors.title}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='row  mb-10 '>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Ad :</label>
                            <input
                              placeholder='Lütfen ad giriniz...'
                              {...UpdateUserFormik.getFieldProps('name')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateUserFormik.touched.name && UpdateUserFormik.errors.name,
                                },
                                {
                                  'is-valid':
                                    UpdateUserFormik.touched.name && !UpdateUserFormik.errors.name,
                                }
                              )}
                              name='name'
                              autoComplete='off'
                            />
                            {UpdateUserFormik.touched.name && UpdateUserFormik.errors.name && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {UpdateUserFormik.errors.name}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Soyad :</label>
                            <input
                              placeholder='Lütfen soyad giriniz...'
                              {...UpdateUserFormik.getFieldProps('surname')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateUserFormik.touched.surname &&
                                    UpdateUserFormik.errors.surname,
                                },
                                {
                                  'is-valid':
                                    UpdateUserFormik.touched.surname &&
                                    !UpdateUserFormik.errors.surname,
                                }
                              )}
                              name='surname'
                              autoComplete='off'
                            />
                            {UpdateUserFormik.touched.surname && UpdateUserFormik.errors.surname && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {UpdateUserFormik.errors.surname}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='row mb-10'>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Telefon :</label>
                            <InputMask
                              placeholder='Lütfen müşteri telefonu giriniz...'
                              {...UpdateUserFormik.getFieldProps('phone')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateUserFormik.touched.phone && UpdateUserFormik.errors.phone,
                                },
                                {
                                  'is-valid':
                                    UpdateUserFormik.touched.phone &&
                                    !UpdateUserFormik.errors.phone,
                                }
                              )}
                              mask='0 (999) 999 99 99'
                              type='tel'
                              name='phone'
                              autoComplete='off'
                            ></InputMask>

                            {UpdateUserFormik.touched.phone && UpdateUserFormik.errors.phone && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {UpdateUserFormik.errors.phone}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>E-Mail :</label>
                            <input
                              placeholder='Lütfen müşteri emaili giriniz...'
                              {...UpdateUserFormik.getFieldProps('email')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateUserFormik.touched.email && UpdateUserFormik.errors.email,
                                },
                                {
                                  'is-valid':
                                    UpdateUserFormik.touched.email &&
                                    !UpdateUserFormik.errors.email,
                                }
                              )}
                              type='email'
                              name='email'
                              autoComplete='off'
                            />
                            {UpdateUserFormik.touched.email && UpdateUserFormik.errors.email && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {UpdateUserFormik.errors.email}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='row mb-10'>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              Kullanıcı Tipi:
                            </label>

                            <Select
                              {...UpdateUserFormik.getFieldProps('userTypeId')}
                              className={clsx(
                                '',
                                {
                                  'is-invalid':
                                    UpdateUserFormik.touched.userTypeId &&
                                    UpdateUserFormik.errors.userTypeId,
                                },
                                {
                                  'is-valid':
                                    UpdateUserFormik.touched.userTypeId &&
                                    !UpdateUserFormik.errors.userTypeId,
                                }
                              )}
                              onChange={(e) => {
                                setSelectedUserType(e as any)
                              }}
                              value={selectedUserType}
                              placeholder='Lütfen bir kullanıcı tipi seçiniz..'
                              options={userTypes}
                            />
                            {UpdateUserFormik.touched.userTypeId &&
                              UpdateUserFormik.errors.userTypeId && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateUserFormik.errors.userTypeId}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Müşteri :</label>
                            <Select
                              {...UpdateUserFormik.getFieldProps('customerId')}
                              className={clsx(
                                '',
                                {
                                  'is-invalid':
                                    UpdateUserFormik.touched.customerId &&
                                    UpdateUserFormik.errors.customerId,
                                },
                                {
                                  'is-valid':
                                    UpdateUserFormik.touched.customerId &&
                                    !UpdateUserFormik.errors.customerId,
                                }
                              )}
                              onChange={(e) => {
                                setSelectedCustomer(e as any)
                              }}
                              value={selectedCustomer}
                              placeholder='Lütfen bir müşteri seçiniz..'
                              options={customerOptions}
                            />
                            {UpdateUserFormik.touched.customerId &&
                              UpdateUserFormik.errors.customerId && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateUserFormik.errors.customerId}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className='row mb-10'>
                          <div className='col-sm-2  p-0'>
                            <p className='fs-6 fw-bolder '>Aktif Mi ? : </p>
                          </div>
                          <div className='col-sm-1  p-0'>
                            <div className='form-check form-check-custom form-check-solid '>
                              {
                                <input
                                  {...UpdateUserFormik.getFieldProps('isActive')}
                                  className='form-check-input '
                                  type='checkbox'
                                  name='isActive'
                                  checked={currentUserIsActive}
                                  onChange={(e) => {
                                    setcurrentUserIsActive(e.target.checked)
                                  }}
                                />
                              }
                            </div>
                          </div>
                        </div>
                        {/* end::Form group */}
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>Güncelle</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Lütfen Bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='NewUserModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={CreateUserFormik.handleSubmit} noValidate>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Yeni Kullanıcı</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Ünvan :</label>
                        <input
                          placeholder='Lütfen müşteri ünvanı giriniz...'
                          {...CreateUserFormik.getFieldProps('title')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateUserFormik.touched.title && CreateUserFormik.errors.title,
                            },
                            {
                              'is-valid':
                                CreateUserFormik.touched.title && !CreateUserFormik.errors.title,
                            }
                          )}
                          type='text'
                          name='title'
                          autoComplete='off'
                        />
                        {CreateUserFormik.touched.title && CreateUserFormik.errors.title && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                              {CreateUserFormik.errors.title}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Ad :</label>
                          <input
                            placeholder='Lütfen ad giriniz...'
                            {...CreateUserFormik.getFieldProps('name')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.name && CreateUserFormik.errors.name,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.name && !CreateUserFormik.errors.name,
                              }
                            )}
                            name='name'
                            autoComplete='off'
                          />
                          {CreateUserFormik.touched.name && CreateUserFormik.errors.name && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateUserFormik.errors.name}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Soyad :</label>
                          <input
                            placeholder='Lütfen soyad giriniz...'
                            {...CreateUserFormik.getFieldProps('surname')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.surname &&
                                  CreateUserFormik.errors.surname,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.surname &&
                                  !CreateUserFormik.errors.surname,
                              }
                            )}
                            name='surname'
                            autoComplete='off'
                          />
                          {CreateUserFormik.touched.surname && CreateUserFormik.errors.surname && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateUserFormik.errors.surname}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Telefon :</label>
                          <InputMask
                            placeholder='Lütfen kullanıcı telefonu giriniz...'
                            {...CreateUserFormik.getFieldProps('phone')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.phone && CreateUserFormik.errors.phone,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.phone && !CreateUserFormik.errors.phone,
                              }
                            )}
                            mask='0 (999) 999 99 99'
                            type='tel'
                            name='phone'
                            autoComplete='off'
                          ></InputMask>
                          {CreateUserFormik.touched.phone && CreateUserFormik.errors.phone && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateUserFormik.errors.phone}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>E-Mail :</label>
                          <input
                            placeholder='Lütfen kullanıcı emaili giriniz...'
                            {...CreateUserFormik.getFieldProps('email')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.email && CreateUserFormik.errors.email,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.email && !CreateUserFormik.errors.email,
                              }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                          />
                          {CreateUserFormik.touched.email && CreateUserFormik.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateUserFormik.errors.email}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Şifre :</label>
                          <input
                            placeholder='Lütfen şifre giriniz...'
                            {...CreateUserFormik.getFieldProps('password')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.password &&
                                  CreateUserFormik.errors.password,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.password &&
                                  !CreateUserFormik.errors.password,
                              }
                            )}
                            type='email'
                            name='password'
                            autoComplete='off'
                          />
                          {CreateUserFormik.touched.password && CreateUserFormik.errors.password && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateUserFormik.errors.password}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            Şifre Tekrar :
                          </label>
                          <input
                            placeholder='Lütfen şifrenizi tekrar giriniz...'
                            {...CreateUserFormik.getFieldProps('passwordAgain')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.passwordAgain &&
                                  CreateUserFormik.errors.passwordAgain,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.passwordAgain &&
                                  !CreateUserFormik.errors.passwordAgain,
                              }
                            )}
                            type='email'
                            name='passwordAgain'
                            autoComplete='off'
                          />
                          {CreateUserFormik.touched.passwordAgain &&
                            CreateUserFormik.errors.passwordAgain && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateUserFormik.errors.passwordAgain}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            Kullanıcı Tipi
                          </label>

                          <Select
                            {...CreateUserFormik.getFieldProps('userTypeId')}
                            className={clsx(
                              '',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.userTypeId &&
                                  CreateUserFormik.errors.userTypeId,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.userTypeId &&
                                  !CreateUserFormik.errors.userTypeId,
                              }
                            )}
                            onChange={(e) => {
                              setSelectedUserType(e as any)
                            }}
                            value={selectedUserType}
                            placeholder='Lütfen bir kullanıcı tipi seçiniz..'
                            options={userTypes}
                          />
                          {CreateUserFormik.touched.userTypeId &&
                            CreateUserFormik.errors.userTypeId && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateUserFormik.errors.userTypeId}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Müşteri :</label>
                          <Select
                            {...CreateUserFormik.getFieldProps('customerId')}
                            className={clsx(
                              '',
                              {
                                'is-invalid':
                                  CreateUserFormik.touched.customerId &&
                                  CreateUserFormik.errors.customerId,
                              },
                              {
                                'is-valid':
                                  CreateUserFormik.touched.customerId &&
                                  !CreateUserFormik.errors.customerId,
                              }
                            )}
                            onChange={(e) => {
                              setSelectedCustomer(e as any)
                            }}
                            value={selectedCustomer}
                            placeholder='Lütfen bir müşteri seçiniz..'
                            options={customerOptions}
                          />
                          {CreateUserFormik.touched.customerId &&
                            CreateUserFormik.errors.customerId && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateUserFormik.errors.customerId}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>Oluştur</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Lütfen Bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='UserAuthModal'>
              <div className='modal-dialog '>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Kullanıcı Yetkileri</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body text-center mb-10 mt-5'>
                    {/* begin::Form group */}
                    <div className='row text-center'>
                      <div className='col-md-12 mb-10'>
                        <p className='fs-3'>
                          <strong> "{currentUser.name + ' ' + currentUser.surname}"</strong> adlı
                          kullanıcının yetkilerini düzenliyorsunuz.
                        </p>
                        <br />
                        <div className='border border-secondary h-100'>
                          <CheckboxTree
                            nodes={authTreeNodes}
                            checked={checkedAuths}
                            expanded={expandedAuths}
                            checkModel='all'
                            onCheck={(checked) => {
                              console.log(checked)

                              setcheckedAuths(checked)
                            }}
                            onExpand={(expanded) => setexpandedAuths(expanded)}
                            iconsClass='fa5'
                            noCascade={true}
                          />
                        </div>
                      </div>
                    </div>

                    {/* end::Form group */}
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Kapat
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        var model: UpdateUserMenuItemRightModel = {
                          userId: currentUser.id,
                          menuItemIds: checkedAuths,
                        }
                        UpdateUserMenuItemRightsByGroup(model)
                      }}
                      className='btn btn-primary'
                    >
                      {!isLoading && <span>Güncelle</span>}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Lütfen Bekleyin...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='GroupModal'>
              <div className='modal-dialog '>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Kullanıcı Grupları</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body  mb-10 mt-5'>
                    {/* begin::Form group */}
                    <div className='row '>
                      <div className='col-md-12 mb-10'>
                        <div className=' text-center'>
                          <p className='fs-3'>
                            <strong> "{currentUser.name + ' ' + currentUser.surname}"</strong> adlı
                            kullanıcıyı eklemek istediğiniz grupları aşağıdan seçebilirsiniz.
                          </p>
                        </div>

                        <br />
                        <div className='list-group border border-secondary h-100'>
                          {groups.map(({id, groupName, description}, index) => {
                            var isCheched = currentUserGroups.includes(id.toString())
                            return (
                              <>
                                <div key={id} className='list-group-item list-group-item-action'>
                                  <div className=' form-check form-check-custom form-check-solid mb-5 mt-5 '>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value={id}
                                      key={id}
                                      onChange={(event) =>
                                        HandleGroupChange(event.target.value, event.target.checked)
                                      }
                                      checked={isCheched}
                                    />
                                    <label className='form-check-label fw-bold fs-6'>
                                      {groupName}
                                    </label>
                                  </div>
                                  <p> {description}</p>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>

                    {/* end::Form group */}
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Kapat
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        if (currentUserGroups.length === 0) {
                          mySwal
                            .fire('Bilgilendirme !', 'En az bir grup seçmelisiniz.', 'info')
                            .then(() => {
                              setLoading(false)
                            })
                        } else {
                          var model: UpdateUserGroupsModel = {
                            userId: currentUser.id,
                            groupIds: currentUserGroups,
                          }
                          UpdateUserGroupsByUserId(model)
                        }
                      }}
                      className='btn btn-primary'
                    >
                      {!isLoading && <span>Güncelle</span>}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Lütfen Bekleyin...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default UserManagement
