/* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {UserMenuItemModel} from '../../../../app/modules/auth/models/UserMenuItemModel'
import {useEffect, useState} from 'react'

export function AsideMenuMain() {
  // const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [userMenuItems, setuserMenuItems] = useState<UserMenuItemModel[]>([])
  const BuildMenu = (menuItems: UserMenuItemModel[]) => {
    return (
      <>
        {menuItems &&
          menuItems.map((menuItem) => {
            if (menuItem.childs.length > 0) {
              return (
                <div key={menuItem.id}>
                  <AsideMenuItemWithSub
                    to={menuItem.menuItemUrl}
                    title={menuItem.displayText}
                    icon={menuItem.icon}
                  >
                    {BuildMenu(menuItem.childs)}
                  </AsideMenuItemWithSub>
                </div>
              )
            } else {
              return (
                <div key={menuItem.id}>
                  <AsideMenuItem
                    to={menuItem.menuItemUrl}
                    title={menuItem.displayText}
                    icon={menuItem.icon}
                    hasBullet={menuItem.icon!.length < 1}
                  />
                </div>
              )
            }
          })}
      </>
    )
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setuserMenuItems([])
      var xMenuItems = user.userMenuItems.sort((a, b) => {
        if (a.displayText < b.displayText) {
          return -1
        }
        if (a.displayText > b.displayText) {
          return 1
        }
        return 0
      })

      setuserMenuItems(xMenuItems)
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      {userMenuItems.map((menuItem) => {
        if (menuItem.childs.length > 0) {
          if (menuItem.isMain) {
            return (
              <>
                <div className='menu-item' key={menuItem.id}>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      {menuItem.displayText}
                    </span>
                  </div>
                </div>
                {BuildMenu(menuItem.childs)}
                
              </>
            )
          } else {
            return (
              <div key={menuItem.id}>
                <AsideMenuItemWithSub
                  to={menuItem.menuItemUrl}
                  title={menuItem.displayText}
                  icon={menuItem.icon}
                >
                  {BuildMenu(menuItem.childs)}
                </AsideMenuItemWithSub>
                
              </div>
              
            )
          }
        } else {
          return (
            <div key={menuItem.id}>
              <AsideMenuItem
                to={menuItem.menuItemUrl}
                title={menuItem.displayText}
                icon={menuItem.icon}
                hasBullet={menuItem.icon!.length < 1}
              />
              
            </div>
          )
        }
      })}
    </>
  )
}
