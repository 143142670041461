import React, {useEffect, useState} from 'react'
import tr from 'date-fns/locale/tr'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  DownloadArchivePDF,
  DownloadArchiveZIP,
  GetArchives,
  GetSingleArchive,
} from './ApiOperations'
import {ArchiveFileModel, ArchiveModel, ArchiveParamsModel} from './Models'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, {registerLocale} from 'react-datepicker'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {format} from 'date-fns'
import {saveAs} from 'file-saver'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {GetIsPermissionGranted} from '../general/GeneralApiOperations'
const mySwal = withReactContent(Swal)
registerLocale('tr', tr)

const Archive = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [isLoading, setLoading] = useState(false)
  const [isGranted, setisGranted] = useState(false)
  const [isViewing, setIsViewing] = useState(false)
  const [isSingleDownloading, setSingleDownloading] = useState(false)
  const [isDownloading, setDownloading] = useState(false)
  const [archives, setArchives] = useState<ArchiveModel[]>([])
  const [refNo, setRefNo] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())

  const DownloadFile = (file: ArchiveFileModel) => {
    debugger
    let a = document.createElement('a')
    a.href = 'data:application/octet-stream;base64,' + file.base64
    a.download = file.fileName
    a.click()
  }

  const ViewFile = (file: ArchiveFileModel) => {
    var dataType = ''

    switch (file.extension) {
      case '.pdf':
        dataType = 'application/pdf'
        break
      case '.png':
        dataType = 'image/png'
        break
      case '.jpg':
        dataType = 'image/jpeg'
        break
      case '.jpeg':
        dataType = 'image/jpeg'
        break
      case '.tiff':
        dataType = 'image/tiff'
        break
      case '.html':
        dataType = 'text/html'
        break
      default:
        break
    }

    var byteCharacters = atob(file.base64)
    var byteNumbers = new Array(byteCharacters.length)
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    var xfile = new Blob([byteArray], {type: 'application/pdf;base64'})
    var fileURL = URL.createObjectURL(xfile)
    window.open(fileURL)
    // var win = window.open()

    // win!.document.write(
    //   '<iframe src="data:${dataType};base64,' +
    //     file.base64 +
    //     '" frameborder="0" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" allowfullscreen>' +
    //     '</iframe>'
    // )
  }

  const ViewSingleArchve = (archiveId: number) => {
    setIsViewing(true)
    GetSingleArchive(archiveId)
      .then((response) => {
        var archiveFile = response.data
        if (archiveFile.base64 != '' && archiveFile.base64 !== null) {
          debugger
          ViewFile(archiveFile)
          setIsViewing(false)
        } else {
          mySwal
            .fire(
              'Hata !',
              'Bir hata oluştu , arşiv görüntülenmeye uygun olmayabilir.İndirmeyi deneyebilirsiniz.',
              'error'
            )
            .then(() => {
              setIsViewing(false)
            })
        }
      })
      .catch(() => {
        mySwal
          .fire(
            'Hata !',
            'Bir hata oluştu , arşiv görüntülenmeye uygun olmayabilir.İndirmeyi deneyebilirsiniz.',
            'error'
          )
          .then(() => {
            setIsViewing(false)
          })
      })
  }
  const DownloadSingleArchve = (archiveId: number) => {
    setSingleDownloading(true)
    GetSingleArchive(archiveId)
      .then((response) => {
        var archiveFile = response.data
        if (archiveFile.base64 !== '' && archiveFile.base64 !== null) {
          DownloadFile(archiveFile)
          setSingleDownloading(false)
        } else {
          mySwal
            .fire('Hata !', 'Bir hata oluştu , arşiv indirmeye uygun olmayabilir.', 'error')
            .then(() => {
              setSingleDownloading(false)
            })
        }
      })
      .catch(() => {
        mySwal
          .fire('Hata !', 'Bir hata oluştu , arşiv indirmeye uygun olmayabilir.', 'error')
          .then(() => {
            setSingleDownloading(false)
          })
      })
  }
  const DownloadArchvePDF = (type: string, refNo: string) => {
    setDownloading(true)
    DownloadArchivePDF(type, refNo)
      .then((response) => {
        var archiveFile = response.data
        if (archiveFile.base64 !== '' && archiveFile.base64 !== null) {
          DownloadFile(archiveFile)

          setDownloading(false)
          mySwal
            .fire(
              'Başarılı !',
              'Dosya indirme işlemi başlatıldı. Dosya boyutuna bağlı olarak inmesi biraz zaman alabilir. ',
              'success'
            )
            .then(() => {})
        } else {
          mySwal
            .fire(
              'Hata !',
              'Bir hata oluştu , arşiv istediğiniz formatta indirmeye uygun olmayabilir.',
              'error'
            )
            .then(() => {
              setDownloading(false)
            })
        }
      })
      .catch(() => {
        mySwal
          .fire(
            'Hata !',
            'Bir hata oluştu , arşiv istediğiniz formatta indirmeye uygun olmayabilir.',
            'error'
          )
          .then(() => {
            setDownloading(false)
          })
      })
  }
  const DownloadArchveZIP = (type: string, refNo: string) => {
    setDownloading(true)
    DownloadArchiveZIP(type, refNo)
      .then((response) => {
        var archiveFile = response.data
        if (archiveFile.base64 !== '' && archiveFile.base64 !== null) {
          DownloadFile(archiveFile)

          setDownloading(false)
          mySwal
            .fire(
              'Başarılı !',
              'Dosya indirme işlemi başlatıldı. Dosya boyutuna bağlı olarak inmesi biraz zaman alabilir. ',
              'success'
            )
            .then(() => {})
        } else {
          mySwal
            .fire(
              'Hata !',
              'Bir hata oluştu , arşiv istediğiniz formatta indirmeye uygun olmayabilir.',
              'error'
            )
            .then(() => {
              setDownloading(false)
            })
        }
      })
      .catch(() => {
        mySwal
          .fire(
            'Hata !',
            'Bir hata oluştu , arşiv istediğiniz formatta indirmeye uygun olmayabilir.',
            'error'
          )
          .then(() => {
            setDownloading(false)
          })
      })
  }

  const GetData = () => {
    if (refNo.length <= 8) {
      setLoading(true)
      var xStartDate = format(startDate, 'dd-MM-yyyy')
      var xEndDate = format(endDate, 'dd-MM-yyyy')
      var xType = type.trim() === 'Tip Seçiniz' ? '' : type
      var params: ArchiveParamsModel = {
        refNo: refNo,
        type: xType,
        startDate: xStartDate,
        endDate: xEndDate,
      }
      GetArchives(params)
        .then((response) => {
          var data = response.data
          setArchives(data)
          mySwal.fire('Başarılı !', 'İşlem başarıyla tamamlandı', 'success').then(() => {
            setLoading(false)
          })
        })
        .catch(() => {
          mySwal
            .fire('Hata !', 'Bir hata oluştu, lütfen daha sonra tekrar deneyin.', 'error')
            .then(() => {
              setLoading(false)
            })
        })
    } else {
      mySwal
        .fire('Bilgilendirme !', 'Referans numarası maksimum 8 karakter olmalı', 'info')
        .then(() => {})
    }
  }
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/archive').then((response) => {
        var isGranted = response.data
        setisGranted(isGranted)

        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [])
  return (
    <>
      {isGranted ? (
        <>
          <PageTitle>Arşiv Görüntüleme</PageTitle>
          <div className='card card-custom  card-stretch shadow mb-5 w-100 '>
            <div className='card-body'>
              <div className='row text-center'>
                <div className='col-md-3'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Referans No:</label>
                  <input
                    placeholder='Referans No giriniz...'
                    className='form-control form-control-lg form-control-solid text-center'
                    type='text'
                    name='refNo'
                    autoComplete='off'
                    value={refNo}
                    onChange={(e) => {
                      setRefNo(e.target.value)
                    }}
                  />
                  {refNo.length > 8 ? (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-danger'>
                        Maksimum 8 karakter olmalı
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='col-md-2'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Tip:</label>
                  <select
                    className='form-select form-select-solid'
                    aria-label='Select example'
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                  >
                    <option>Tip Seçiniz</option>
                    <option value='T'>İthalat</option>
                    <option value='H'>İhracat</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Başlangıç Tarihi:</label>
                  <DatePicker
                    className='form-control form-control-lg form-control-solid text-center'
                    locale='tr'
                    dateFormat='dd-MM-yyyy'
                    selected={startDate}
                    onChange={(date) => setStartDate(date!)}
                  />
                  {startDate.toString().length > 0 && startDate.toString().length < 10 ? (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-danger'>
                        Minimum 10 karakter olmalı
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='col-md-2'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Bitiş Tarihi:</label>
                  <DatePicker
                    className='form-control form-control-lg form-control-solid text-center'
                    locale='tr'
                    dateFormat='dd-MM-yyyy'
                    selected={endDate}
                    onChange={(date) => setEndDate(date!)}
                  />
                  {endDate.toString().length > 0 && endDate.toString().length < 10 ? (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-danger'>
                        Minimum 10 karakter olmalı
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className='col-md-3'>
                  <button onClick={GetData} className='btn btn-primary mt-8 w-75'>
                    {!isLoading && (
                      <span>
                        <i className='fa fa-search' aria-hidden='true'></i>Getir
                      </span>
                    )}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Lütfen Bekleyin...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-custom  card-stretch shadow mb-5 w-100 '>
            <div className='card-body '>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='accordion' id='kt_accordion_1'>
                    {archives.length == 0 ? (
                      <div className='alert alert-warning d-flex align-items-center p-5  text-center'>
                        <div className='d-flex flex-column w-100'>
                          <h5 className='mb-1'>Kayıt Bulunamadı</h5>
                          <span>Yukarıdaki paneli kullanarak sorgulama yapabilirsiniz</span>
                        </div>
                      </div>
                    ) : (
                      <div className='text-center '>
                        {archives.map((archive, index) => {
                          var accordionHeader = `kt_accordion_1_header_${index}`
                          var accordionBody = `kt_accordion_1_body_${index}`
                          return (
                            <div className='accordion-item border border-secondary rounded'>
                              <div
                                className='accordion-header border border-secondary rounded'
                                id={accordionHeader}
                              >
                                <button
                                  className='accordion-button fs-4 fw-bold collapsed'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={'#' + accordionBody}
                                  aria-expanded='false'
                                  aria-controls={accordionBody}
                                >
                                  Arşiv Detayı ({archive.refNo})
                                </button>
                              </div>
                              <div
                                id={accordionBody}
                                className='accordion-collapse collapse'
                                aria-labelledby={accordionHeader}
                                data-bs-parent='#kt_accordion_1'
                              >
                                <div className='accordion-body'>
                                  <div className='table-responsive'>
                                    <table className='table table-striped gy-7 gs-7 text-center border border-secondary rounded'>
                                      <thead>
                                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                          <th className=' border border-secondary'>Tip</th>
                                          <th className=' border border-secondary'>Tarih</th>
                                          <th className=' border border-secondary'>Aciklama</th>
                                          <th className=' border border-secondary'>Arşivleyen</th>
                                          <th className=' border border-secondary'>Dosya Adı</th>
                                          <th className=' border border-secondary'>Görüntüle</th>
                                          <th className=' border border-secondary'>İndir</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {archive.archiveDetails.map((detail) => {
                                          return (
                                            <tr>
                                              <td>{detail.type}</td>
                                              <td>{detail.createDate}</td>
                                              <td>{detail.description}</td>
                                              <td>{detail.kulKod}</td>
                                              <td>{detail.fileName}</td>
                                              <td>
                                                <button
                                                  className='btn btn-sm btn-warning w-75'
                                                  onClick={() => {
                                                    ViewSingleArchve(detail.id)
                                                  }}
                                                >
                                                  {!isViewing && (
                                                    <span>
                                                      {' '}
                                                      <i
                                                        className='fa fa-eye'
                                                        aria-hidden='true'
                                                      ></i>
                                                      Görüntüle
                                                    </span>
                                                  )}

                                                  {isViewing && (
                                                    <span
                                                      className='indicator-progress'
                                                      style={{display: 'block'}}
                                                    >
                                                      <i
                                                        className='fa fa-eye'
                                                        aria-hidden='true'
                                                      ></i>
                                                      Lütfen Bekleyin...
                                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                  )}
                                                </button>
                                              </td>
                                              <td>
                                                <button
                                                  className='btn btn-sm btn-info w-75'
                                                  onClick={() => {
                                                    DownloadSingleArchve(detail.id)
                                                  }}
                                                >
                                                  {!isSingleDownloading && (
                                                    <span>
                                                      {' '}
                                                      <i
                                                        className='fa fa-arrow-circle-down'
                                                        aria-hidden='true'
                                                      ></i>
                                                      İndir
                                                    </span>
                                                  )}

                                                  {isSingleDownloading && (
                                                    <span
                                                      className='indicator-progress'
                                                      style={{display: 'block'}}
                                                    >
                                                      <i
                                                        className='fa fa-arrow-circle-down'
                                                        aria-hidden='true'
                                                      ></i>
                                                      Lütfen Bekleyin...
                                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                  )}
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                      <tfoot className='text-right border border-secondary rounded'>
                                        <tr>
                                          <td colSpan={6}></td>
                                          <td>
                                            <div className='btn-group w-75' role='group'>
                                              <button
                                                id='btnGroupDrop1'
                                                type='button'
                                                className='btn btn-sm btn-primary  dropdown-toggle w-75'
                                                data-bs-toggle='dropdown'
                                                aria-expanded='false'
                                              >
                                                {!isDownloading && (
                                                  <span>
                                                    <i
                                                      className='fa fa-download'
                                                      aria-hidden='true'
                                                    ></i>
                                                    Tümünü İndir
                                                  </span>
                                                )}

                                                {isDownloading && (
                                                  <span
                                                    className='indicator-progress'
                                                    style={{display: 'block'}}
                                                  >
                                                    <i
                                                      className='fa fa-download'
                                                      aria-hidden='true'
                                                    ></i>
                                                    Lütfen Bekleyin...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                  </span>
                                                )}
                                              </button>
                                              <ul
                                                className='dropdown-menu bg-primary'
                                                aria-labelledby='btnGroupDrop1'
                                              >
                                                <li>
                                                  <a
                                                    className='dropdown-item btn btn-sm btn-primary'
                                                    onClick={() => {
                                                      DownloadArchvePDF(archive.type, archive.refNo)
                                                    }}
                                                    href='#'
                                                  >
                                                    <i
                                                      className='fa fa-file'
                                                      aria-hidden='true'
                                                    ></i>
                                                    Toplu .PDF
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className='dropdown-item btn btn-sm btn-primary'
                                                    onClick={() => {
                                                      DownloadArchveZIP(archive.type, archive.refNo)
                                                    }}
                                                    href='#'
                                                  >
                                                    <i
                                                      className='fa fa-archive'
                                                      aria-hidden='true'
                                                    ></i>
                                                    Toplu .Zip
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>!</h3>
        </>
      )}
    </>
  )
}

export default Archive
