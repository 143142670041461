import React, {useEffect, useState} from 'react'
import {CreateReport, GetCustomersZReports, GetReports, UpdateReport} from './ApiOperations'
import {
  ReportModel,
  UpdateReportModel,
  Option,
  AddReportToCustomerModel,
  CustomerZReportModel,
} from './Models'
import Select from 'react-select'
import {useParams} from 'react-router-dom'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {KTSVG} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {initialCreateReportValues, initialUpdateReportValues, updateReportSchema} from './Form'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import {GetIsPermissionGranted} from '../../general/GeneralApiOperations'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const mySwal = withReactContent(Swal)

const CustomerReportManagement = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {customerId} = useParams<{customerId: string}>()
  const [isGranted, setisGranted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [tableRows, setTableRows] = useState<any[]>([])
  const [reportOptions, setReportOptions] = useState<Option[]>([])
  const [selectedReport, setSelectedReport] = useState<Option>()
  const [reportUrl, setReportUrl] = useState('')
  const [currentReport, setCurrentReport] = useState<UpdateReportModel>(initialUpdateReportValues)
  const [currentReportIsDashboard, setCurrentReportIsDashboard] = useState(false)
  //#region Grid Methods
  const onGridReady = (params: any) => {
    var gridApi = params.api
    gridApi.sizeColumnsToFit()
  }
  const EditCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation() // don't select this row after clicking

      var report = params.data

      var xReport: UpdateReportModel = {
        id: report.id,
        customerId: report.customerId,
        zReportId: report.zReportId,
        reportUrl: report.reportUrl,
        reportName: report.reportName,
        isDashboard: report.isDashboard,
      }

      setCurrentReport(xReport)
      setCurrentReportIsDashboard(xReport.isDashboard)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#UpdateReportModal'
        className='btn btn-sm btn-info w-100'
        onClick={onClick}
      >
        {!isLoading && <span>Düzenle</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Lütfen Bekleyin...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }

  const IsDashboardCellRenderer = (params: any) => {
    var isDashboard = params.data.isDashboard
    return (
      <div className='mb-0'>
        <div className='form-check form-check-custom form-check-solid'>
          {isDashboard ? (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' checked />
          ) : (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' />
          )}
        </div>
      </div>
    )
  }
  //#endregion

  const CreateNewReport = () => {
    setLoading(true)
    if (selectedReport === undefined || selectedReport?.value === 0) {
      mySwal.fire('Bilgilendirme !', 'Lütfen bir rapor seçiniz.', 'info').then(() => {
        setLoading(false)
      })
    } else if (reportUrl === undefined || reportUrl.length < 10) {
      mySwal
        .fire(
          'Bilgilendirme !',
          'Rapor URL bilgisi minimum 10 karakter uzunluğunda olmalıdır.',
          'info'
        )
        .then(() => {
          setLoading(false)
        })
    } else {
      var model: AddReportToCustomerModel = {
        customerId: parseInt(customerId),
        zReportId: selectedReport!.value,
        isDashboard: currentReportIsDashboard,
        reportUrl: reportUrl,
      }
      CreateReport(model)
        .then(() => {
          mySwal.fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success').then(() => {
            GetData()
            setLoading(false)
            window.location.reload()
          })
        })
        .catch(() => {
          mySwal
            .fire('Hata !', 'Bir hata oluştu , lütfen daha sonra tekrar deneyin', 'error')
            .then(() => {
              GetData()
              setLoading(false)
              window.location.reload()
            })
        })
    }
  }
  const GetAllReports = () => {
    GetReports()
      .then((response) => {
        var reports = response.data
        var selectOptions: Option[] = []
        GetCustomersZReports(customerId)
          .then((response) => {
            debugger
            var customerReports = response.data
            if (reports.length > 0) {
              setTableRows(customerReports)
              reports.forEach((report) => {
                debugger
                var recordCount = customerReports.filter((c) => c.zReportId === report.id).length
                if (recordCount === 0) {
                  var option = {
                    label: report.reportName,
                    value: report.id,
                  }
                  selectOptions.push(option)
                }
              })
              setReportOptions(selectOptions)
            } else {
              mySwal
                .fire(
                  'Bilgilendirme',
                  'Bu ekranı kullanabilmek için en az bir rapor tanımı var olması gerekiyor. Rapor tanımı oluşturma sayfasına yönlendirileceksiniz. ',
                  'info'
                )
                .then(() => {
                  window.location.href = '/report-management'
                })
            }
          })
          .catch(() => {
           
          })
      })
      .catch()
  }
  const GetData = () => {
    setSelectedReport(undefined)

    GetAllReports()
  }
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/customer-report-management').then((response) => {
        var isGranted = response.data
        setisGranted(isGranted)

        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
      GetData()
    }
    return () => {
      isMounted = false
    }
  }, [customerId])
  const UpdateReportFormik = useFormik({
    enableReinitialize: true,
    initialValues: currentReport,
    validationSchema: updateReportSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      values.isDashboard = currentReportIsDashboard
      setLoading(true)
      setTimeout(() => {
        UpdateReport(values as UpdateReportModel)
          .then((reponse) => {
            mySwal.fire('Başarılı !', '<p> İşlem başarıyla tamamlandı.</p>', 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              'Hata !',
              '<p>Bir hata oluştu. Lütfen daha sonra tekrar deneyin.</p>',
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })
  return (
    <>
      {isGranted ? (
        <>
          <>
            <div className='card'>
              <div className='card-body'>
                <div className='row alert alert-primary d-flex align-items-center pb-10'>
                  <div className='col-md-9'>
                    <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'> </span>

                    <div className='d-flex flex-column'>
                      <h5 className='mb-1'>{`${user.companyName} Rapor Yönetimi`}</h5>
                      <span>
                        Şu anda <strong className='tex-muted'>{user.companyName} </strong>müşterisine
                        ait rapor tanımlarını yönetiyorsunuz.
                      </span>
                    </div>
                  </div>
                  <div className='col-md-3 '>
                    <button
                      data-bs-toggle='modal'
                      data-bs-target='#NewReportModal'
                      className='btn btn-md btn-primary float-right w-100'
                    >
                      <i className='fa fa-plus' aria-hidden='true'></i>
                      Yeni Rapor Tanımı
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card card-custom  card-stretch shadow'>
                      <div className='card-body'>
                        <div className='ag-theme-alpine' style={{height: 600, width: '100%'}}>
                          <AgGridReact
                            frameworkComponents={{
                              isDashboardCellRenderer: IsDashboardCellRenderer,
                              editCellRenderer: EditCellRenderer,
                            }}
                            defaultColDef={{
                              sortable: true,
                              flex: 1,
                              filter: true,
                              resizable: true,
                            }}
                            onGridReady={onGridReady}
                            rowData={tableRows}
                          >
                            <AgGridColumn field='id' hide={true} />
                            <AgGridColumn field='customerId' hide={true} />
                            <AgGridColumn field='zReportId' hide={true} />
                            <AgGridColumn field='customerTitle' hide={true} />
                            <AgGridColumn field='reportName' headerName='Rapor Adı' />
                            <AgGridColumn field='reportUrl' headerName='Rapor URL' />
                            <AgGridColumn field='createdByStr' headerName='Oluşturan' />
                            <AgGridColumn field='createdDateStr' headerName='Oluşturma Tarihi' />
                            <AgGridColumn
                              field='isDashboard'
                              headerName='Dashboard ?'
                              cellRenderer='isDashboardCellRenderer'
                            />
                            <AgGridColumn
                              field='düzenle'
                              pinned='right'
                              width={125}
                              headerName='Düzenle'
                              cellRenderer='editCellRenderer'
                            />
                          </AgGridReact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='UpdateReportModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={UpdateReportFormik.handleSubmit}>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Rapor Güncelle</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Rapor URL :</label>
                        <input
                          placeholder='Lütfen grup adı giriniz...'
                          {...UpdateReportFormik.getFieldProps('reportUrl')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateReportFormik.touched.reportUrl &&
                                UpdateReportFormik.errors.reportUrl,
                            },
                            {
                              'is-valid':
                                UpdateReportFormik.touched.reportUrl &&
                                !UpdateReportFormik.errors.reportUrl,
                            }
                          )}
                          type='text'
                          name='reportUrl'
                          autoComplete='off'
                        />
                        {UpdateReportFormik.touched.reportUrl &&
                          UpdateReportFormik.errors.reportUrl && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateReportFormik.errors.reportUrl}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className='row mb-10'>
                        <div className='col-sm-2  p-0'>
                          <p className='fs-6 fw-bolder '>Is Dashboard : </p>
                        </div>

                        <div className='col-sm-1  p-0'>
                          <div className='form-check form-check-custom form-check-solid '>
                            {
                              <input
                                {...UpdateReportFormik.getFieldProps('isDashboard')}
                                className='form-check-input'
                                type='checkbox'
                                name='isDashboard'
                                checked={currentReportIsDashboard}
                                onChange={(e) => {
                                  setCurrentReportIsDashboard(e.target.checked)
                                }}
                              />
                            }
                          </div>
                        </div>
                      </div>

                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>Güncelle</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Lütfen Bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='NewReportModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Rapor Ekle</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body text-center'>
                    {reportOptions.length > 0 ? (
                      <>
                        <div className='row'>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              Rapor Seçimi :
                            </label>
                            <Select
                              onChange={(e) => {
                                setSelectedReport(e as any)
                              }}
                              value={selectedReport}
                              placeholder='Lütfen bir rapor seçiniz..'
                              options={reportOptions}
                            />
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              Rapor URL :
                            </label>
                            <input
                              placeholder='Lütfen rapor url giriniz...'
                              className='form-control form-control-lg form-control-solid text-center '
                              type='text'
                              name='title'
                              autoComplete='off'
                              value={reportUrl}
                              onChange={(e) => {
                                var url = e.target.value
                                setReportUrl(url)
                              }}
                            />
                            {reportUrl.length < 10 ? (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  URL minimum 10 karakter olmalıdır.
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className='row mb-10'>
                          <div className='col-sm-2  p-0'>
                            <p className='fs-6 fw-bolder '>Is Dashboard : </p>
                          </div>
                          <div className='col-sm-1  p-0'>
                            <div className='form-check form-check-custom form-check-solid '>
                              {
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='isDashboard'
                                  checked={currentReportIsDashboard}
                                  onChange={(e) => {
                                    setCurrentReportIsDashboard(e.target.checked)
                                  }}
                                />
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className='row  mt-5 '>
                        <div className='col-md'></div>
                        <div className='col-md-6 text-center'>
                          <p className='text-muted fs-6'>
                            <strong> {user.companyName}</strong> adlı müşteri var olan tüm raporlar
                            için gerekli tanımlara sahip. Var olan tanımları düzenlemek için lütfen
                            bu pencereyi kapatın.
                          </p>
                        </div>
                        <div className='col-md'></div>
                      </div>
                    )}
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Kapat
                    </button>
                    <button
                      type='button'
                      disabled={reportOptions.length > 0 ? false : true}
                      onClick={CreateNewReport}
                      className='btn btn-primary'
                    >
                      {!isLoading && <span>Oluştur</span>}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Lütfen Bekleyin...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CustomerReportManagement
