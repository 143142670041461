/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import {GetIsPermissionGranted} from '../general/GeneralApiOperations'
import {GetDashboardData} from './ApiOperations'
import {DashboardModel} from './Models'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row'>
      <div className='col-xxl-12'></div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [isGranted, setisGranted] = useState(false)
  const [dashBoardData, setdashBoardData] = useState<DashboardModel>({reportUrl: ''})
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/dashboard').then((response) => {
        var isGranted = response.data

        setisGranted(isGranted)

         
        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
      GetDashboardData().then((response) => {
        var xdashBoardData = response.data

        setdashBoardData(xdashBoardData)
      })
    }
    return () => {
      isMounted = false
    }
  }, [])
  return (
    <>
      {isGranted ? (
        <>
          <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

            {dashBoardData!.reportUrl.length > 1 ? (
              <div className='row p-0'>
                <div className='col-xxl-12 bg-light p-0'>
                  <div className='w-95 bg-light p-0'>
                    <iframe
                      className='bg-light'
                      style={{
                        position: 'absolute',
                        width: '93%',
                        height: '85%',
                        border: 'solid',
                        borderColor: 'white',
                      }}
                      src={dashBoardData?.reportUrl}
                    ></iframe>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='row p-0 '>
                  <div className='col-xxl-12 bg-light p-0 h-100 '>
                    <div className='card h-100 '>
                      <div className='card-body '>
                        <div className='w-100 bg-light p-0'>
                          <div className='row alert alert-warning d-flex align-items-center pb-10 '>
                            <div className='col-md-9'>
                              <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'> </span>

                              <div className='d-flex flex-column'>
                                <h5 className='mb-1'>{`Dashboard Rapor Tanımı Yapılmamış !`}</h5>
                                <span>
                                  Lütfen{' '}
                                  <strong className='tex-muted'> Rapor Tanım Sayfası </strong>'ndan
                                  bir raporu dashboard olarak işaretleyiniz..
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </>
      ) : (
        <>
         
        </>
      )}
    </>
  )
}

export {DashboardWrapper}
