import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {ResetUserPassword} from '../redux/AuthCRUD'

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
const mySwal = withReactContent(Swal)

const initialValues = {
  password: '',
  passwordAgain: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(3, 'Minimum 3 karakter olmalı').required('Şifre zorunludur'),
  passwordAgain: Yup.string()
    .min(3, 'Minimum 3 karakter olmalı')
    .required('Şifre tekrarı zorunludur'),
})
const ResetPassword = () => {
  const {passwordResetKey} = useParams<{passwordResetKey: string}>()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isCorrect, setIsCorrect] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (values.password.trim() === values.passwordAgain.trim()) {
        var password = values.password
        var passwordAgain = values.passwordAgain
        setTimeout(() => {
          ResetUserPassword(password, passwordAgain, false, passwordResetKey)
            .then((response) => {
              setLoading(false)
              mySwal
                .fire(
                  'Başarılı !',
                  'Şifreniz başarıyla güncellendi. Giriş yapabilmeniz için giriş sayfasına yönlendiriliyorsunuz.',
                  'success'
                )
                .then(() => {
                  window.location.href = '/auth/login'
                })
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus('')
            })
        }, 1000)
      } else {
        mySwal.fire(
          'Bilgilendirme',
          'Girdiğiniz şifreler eşleşmiyor , lütfen kontrol edin.',
          'info'
        )
      }
    },
  })
  useEffect(() => {
    setTimeout(() => {
      ResetUserPassword('', '', true, passwordResetKey)
        .then((response) => {
          setIsCorrect(true)
        })
        .catch(() => {
          setIsCorrect(false)
          mySwal
            .fire(
              'Hata !',
              'Şifre sıfırlama bağlantısı geçersiz ! Anasayfaya yönlendiriliyorsunuz..',
              'error'
            )
            .then(() => {
              window.location.href = '/auth/login'
            })
        })
    }, 1000)
  }, [passwordResetKey])
  return (
    <>
      {isCorrect ? (
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/background/bg-image.png')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
          }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}
            {/* <a href='#' className='mb-12'>
    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-45px' />
  </a> */}
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='text-center mb-10'>
                  {/* begin::Title */}
                  <h1 className='text-dark mb-3'>Şifrenizi Yenileyin </h1>
                  {/* end::Title */}

                  {/* begin::Link */}
                  <div className='text-gray-400 fw-bold fs-4'>
                    Yeni şifrenizi aşağıdaki kutucuklara girerek şifrenizi güncelleyebilirsiniz.
                  </div>
                  {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      Bir hata oluştu , lütfen daha sonra tekrar deneyin.
                    </div>
                  </div>
                )}

                {/* end::Title */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>Şifre :</label>
                  <input
                    placeholder='Yeni şifrenizi girin.'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.password && formik.errors.password},
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.password}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>Şifre Tekrar :</label>
                  <input
                    placeholder='Yeni şifrenizi tekrar girin.'
                    autoComplete='off'
                    {...formik.getFieldProps('passwordAgain')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.passwordAgain && formik.errors.passwordAgain},
                      {
                        'is-valid': formik.touched.passwordAgain && !formik.errors.passwordAgain,
                      }
                    )}
                  />
                  {formik.touched.passwordAgain && formik.errors.passwordAgain && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.passwordAgain}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                  >
                    {!loading && <span>Güncelle</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Lütfen Bekleyin...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Form group */}
              </form>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ResetPassword
