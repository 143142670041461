import * as Yup from 'yup'

export const initialCreateReportValues = {
  reportName: '',
  reportTypeId:0
}
export const initialUpdateReportValues = {
    id: 0,
    reportName: '',  
    reportTypeId:0, 
    isActive: false  
  }
export const createReportSchema = Yup.object().shape({
  reportName: Yup.string()
    .typeError('Rapor Adı zorunludur')
    .min(5, 'Minimum 5 karakter olmalı')
    .required('Rapor Adı zorunludur'),
    reportTypeId: Yup.number()
      .typeError('Rapor Tipi zorunludur')      
      .required('Rapor Tipi zorunludur'),
})
export const updateReportSchema = Yup.object().shape({
    reportName: Yup.string()
      .typeError('Rapor Adı zorunludur')
      .min(5, 'Minimum 5 karakter olmalı')
      .required('Rapor Adı zorunludur'),
      reportTypeId: Yup.number()
      .typeError('Rapor Tipi zorunludur')      
      .required('Rapor Tipi zorunludur'),
      isActive: Yup.boolean()
  })
  